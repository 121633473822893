<template>
  <main class="page-projects page-update-project-module-visea p-0 d-flex flex-column">
    <canvas hidden id="draw" width="100%"></canvas>
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.show' }">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.visea' }" aria-current="page">Modulo ViSEA</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'projects.tutorial' }">Tutorial</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-grow-1 container-fluid p-0 overflow-hidden d-flex flex-row align-content-stretch align-items-stretch" style="position: relative; z-index: 2;">
      <div class="polygon-info p-3 d-flex flex-column justify-content-between">
        <div class="general-info d-flex flex-column mb-3">
          <h2 class="h5 mb-0 font-weight-bold text-uppercase text-secondary text-center" v-if="nodeActive">Informazioni Poligono</h2>
          <h2 class="h5 mb-0 font-weight-bold text-uppercase text-secondary text-center" v-else>Seleziona un Poligono</h2>
          <div class="d-flex align-items-center justify-content-center my-3 flex-grow-1">
            <canvas id="crop" class="mx-auto" style="width: 100%"></canvas>
          </div>
          <div class="flex-grow-1">
            <canvas id="full" style="width: 100%; border-radius: 5px;"></canvas>
          </div>
        </div>
        <div class="more-polygons px-3 pt-3" v-if="nodes.length > 0">
          <h6 class="font-weight-bold text-uppercase text-secondary">Altri Poligoni della Giornata</h6>
          <div class="polygons-list d-flex pb-2">
            <button class="btn btn-secondary font-weight-bold rounded-circle m-2" v-for="node in nodes" :key="node.id" v-on:click="externalClick(node.id)" :title="'Poligono ' + node.label">
              {{ node.label }}
            </button>
          </div>
        </div>
      </div>
      <div ref='page' class="flex-grow-1 d-flex align-content-stretch align-items-stretch overflow-hidden">
        <div id='graph-container' ref='graph-container' class="w-100"></div>
      </div>
      <div class="appearance-order p-3 d-flex flex-column" v-if="days">
        <h2 class="h5 mb-0 font-weight-bold text-uppercase text-secondary text-center">Ordine di Apparizione</h2>

        <div class="overflow-auto flex-grow-1 px-0 my-3">
          <draggable :list="sequence">
            <div v-for="(sequencePass, index) in sequence" :key="index" class="appearance-group mb-4">
              <div class="group-header p-2 pb-0 text-center">
                <h3 class="h6 font-weight-bold text-uppercase mb-0">Gruppo {{ index + 1 }}</h3>
              </div>
              <div class="group-body p-3">
                <draggable :list="sequencePass" :sort="false" group="days">
                  <div v-for="(day, indexDay) in sequencePass" :key="day" class="appearance-group day p-3 mb-3 d-flex flex-row align-items-center justify-content-between">
                    <p class="mb-0 font-weight-bold text-secondary">Giorno {{ day }}</p>
                    <button title="Rimuovi giorno" class="btn btn-danger btn-sm remove-group" @click.prevent="removeDay(day, index, indexDay)"><i class="fas fa-trash-alt"></i></button>
                  </div>
                </draggable>
              </div>

              <div class="p-3" v-if="sequencePass && sequencePass.length === 0 && sequence.length > 1">
                <button class="btn btn-sm font-weight-bold btn-danger w-100" @click.prevent="removeGroup(index)">Rimuovi</button>
              </div>
            </div>
          </draggable>
        </div>

        <div>
          <button class="btn btn-secondary w-100 font-weight-bold" @click.prevent="addGroup" :disabled="!sequence || (0 === sequence.length) || sequence[sequence.length - 1].length === 0">Nuovo gruppo</button>
        </div>
      </div>
    </div>
    <div class="mode-box d-flex p-3 justify-content-center bg-white">
        <template v-if="true">
          <button class="btn btn-secondary mx-2" @click.prevent="GraphFit">Centra</button>
          <button class="btn btn-secondary mx-2" @click.prevent="checkCycle">Cicli</button>
          <button class="btn btn-secondary mx-2" @click.prevent="GraphDfs">Analizza</button>
          <button class="btn btn-secondary mx-2" @click.prevent="daysGraph">
            <template v-if="days">Grafo Poligoni</template>
            <template v-else>
              Grafo Giornate
            </template>
            </button>
          <button class="btn btn-primary font-weight-bold mx-2" @click.prevent="saveEditorData">Salva</button>
        </template>
      </div>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api';
import validateMixin from '@/libs/Form/mixins/validate';
import alertWithModalMixin from '@/libs/Alert/mixins/alertWithModal';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import ViseaGraph from '@/libs/Editor/viseagraphs';
import draggable from 'vuedraggable';

export default {
  mixins: [validateMixin, alertWithModalMixin, confirmWithModalMixin],
  components: {
    draggable,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      target: null,
      nodeActive: false,
      nodes: [],
      days: false,
      feedback: null,
      projectData: {
        visea_module: null,
      },
      vg: null,
      sequence: null,
    };
  },
  watch: {
    sequence: {
      deep: true,
      handler (newValue, oldValue) {
        if (oldValue === null) {
          return;
        }

        window.vg.updateSequence(newValue);
      },
      immediate: false,
    },
  },
  destroyed () {
    window.vg.destroy();
    window.vg = null;
  },
  mounted () {
    this.$nextTick(() => {
      // Todo: make it works without reload
      this.$refs['graph-container'].width = this.$refs.page.clientWidth;
      this.$refs['graph-container'].height = this.$refs.page.clientHeight;
      if (!window.vg) {
        window.vg = new ViseaGraph('graph-container', (target, related, color) => {
          let polygon;
          this.nodeActive = true;
          const viseaModule = JSON.parse(this.projectData.visea_module);
          const relatedPolygons = viseaModule.polygons.filter(relatedPolygon => related.includes(relatedPolygon.id.toString()));
          this.nodes = viseaModule.overlays.filter(relatedOverlay => related.includes(relatedOverlay.id));
          // this.$log.debug(viseaModule);
          for (let i = 0; i < viseaModule.polygons.length; i++) {
            if (Number.parseInt(target) === viseaModule.polygons[i].id) {
              polygon = viseaModule.polygons[i];
              break;
            }
          }
          let minX = 1000000;
          let minY = minX;
          let maxX = -1000000;
          let maxY = maxX;
          for (let i = 0; i < polygon.points.length; i += 1) {
            const x = polygon.points[i].x;
            const y = polygon.points[i].y;
            if (x < minX) { minX = x; }
            if (y < minY) { minY = y; }
            if (x > maxX) { maxX = x; }
            if (y > maxY) { maxY = y; }
          }
          // const canvas = document.getElementById('draw');
          const out = document.getElementById('crop');
          const full = document.getElementById('full');
          const fullCtx = full.getContext('2d');
          // const ctx = canvas.getContext('2d');
          let outCtx = out.getContext('2d');
          const img = new Image();
          img.onload = () => {
            const outWidth = maxX - minX;
            const outHeight = maxY - minY;
            out.width = 356;
            out.height = out.width * (outHeight / outWidth);
            if (out.height > 185) {
              out.height = 185;
              out.width = out.height * (outWidth / outHeight);
              out.style = '';
            } else {
              out.style = 'width: 100%;';
            }
            // console.log(outHeight);
            // console.log(outWidth);
            // out.width = maxX - minX;
            // out.height = maxY - minY;
            outCtx = out.getContext('2d');
            outCtx.beginPath();
            outCtx.moveTo(
              (polygon.points[0].x - minX) * out.width / outWidth,
              (polygon.points[0].y - minY) * out.height / outHeight);
            for (let i = 1; i < polygon.points.length; i++) {
              const x = (polygon.points[i].x - minX) * out.width / outWidth;
              const y = (polygon.points[i].y - minY) * out.height / outHeight;
              outCtx.lineTo(x, y);
            }
            outCtx.closePath();
            outCtx.clip();
            outCtx.drawImage(img, minX, minY, outWidth, outHeight, 0, 0, out.width, out.height);
            full.height = full.width * (img.height / img.width);
            var oc = document.createElement('canvas');
            var octx = oc.getContext('2d');

            oc.width = img.width * 0.5;
            oc.height = img.height * 0.5;
            octx.drawImage(img, 0, 0, oc.width, oc.height);
            octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);
            fullCtx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, 0, 0, full.width, full.height);
            fullCtx.beginPath();
            relatedPolygons.forEach(currentPoly => {
              fullCtx.moveTo((currentPoly.points[0].x * full.width) / img.width, (currentPoly.points[0].y * full.height) / img.height);
              for (let i = 1; i < currentPoly.points.length; i++) {
                const x = (currentPoly.points[i].x * full.width) / img.width;
                const y = (currentPoly.points[i].y * full.height) / img.height;
                fullCtx.lineTo(x, y);
              }
              fullCtx.closePath();
            });
            fullCtx.fillStyle = color + 'c8';
            fullCtx.fill();
            fullCtx.strokeStyle = '#6e7173';
            fullCtx.stroke();

            fullCtx.beginPath();
            fullCtx.moveTo((polygon.points[0].x * full.width) / img.width, (polygon.points[0].y * full.height) / img.height);
            for (let i = 1; i < polygon.points.length; i++) {
              const x = (polygon.points[i].x * full.width) / img.width;
              const y = (polygon.points[i].y * full.height) / img.height;
              fullCtx.lineTo(x, y);
            }
            fullCtx.closePath();
            fullCtx.fillStyle = color + 'c8'; // 'rgba(200,0,0,0.5)';
            fullCtx.fill();
            fullCtx.strokeStyle = '#36d1c9';
            fullCtx.stroke();
          };
          img.src = this.project.image.url;
        });
      }
      window.vg.setCanvas(document.getElementById('graph-container'));
      const viseaData = JSON.parse(this.projectData.visea_module);
      // console.log(viseaData.overlays);
      window.vg.importSession(viseaData.graph);
      window.vg.loadPolygon(viseaData.overlays);
      window.vg.pushSequence = (day) => {
        if (window.vg.sequence[day] === undefined) {
          this.addDayToLastGroup(day);
          window.vg.sequence[day] = this.sequence.length - 1;
        }
        window.vg.tippy_instance.hide();
      };
      this.externalClick = window.vg.externalClick;
      this.sequence = window.vg.exportSequence();
    });
  },
  methods: {
    addGroup () {
      this.sequence.push([]);
    },
    removeGroup (index) {
      this.sequence.splice(index, 1);
    },
    removeDay (day, indexGroup, indexDay) {
      this.sequence[indexGroup].splice(indexDay, 1);
      window.vg.updateSequence(this.sequence);
      /*
      delete window.vg.sequence[day]; */
    },
    addDayToLastGroup (day) {
      this.sequence[this.sequence.length - 1].push(day);
    },
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.feedback = null;
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      return !this.hasErrors;
    },
    GraphFit () {
      window.vg.cy.fit();
    },
    GraphDfs () {
      this.confirm('Analizzando il grafo, verranno sovrascritte le giornate / gruppi.').then((confirmed) => {
        if (confirmed) {
          window.vg.dfs();
        }
      });
    },
    daysGraph () {
      if (!this.days) {
        window.vg.exportAll();
        const days = window.vg.exportDaysGraph();
        window.vg.loadPolygon(days, true);
      } else {
        window.vg.loadPolygon(window.vg._polygons);
      }
      this.days = window.vg.days;
    },
    saveEditorData () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }
      // const viseaData = JSON.parse(this.projectData.visea_module);
      // viseaData.overlays = window.vg._polygons;
      // this.projectData = JSON.stringify(viseaData);
      const viseaData = JSON.parse(this.projectData.visea_module);
      viseaData.overlays = window.vg._polygons;
      viseaData.graph = window.vg.exportSession();
      this.projectData.visea_module = JSON.stringify(viseaData);
      this.$api.updateProjectModuleVisea(this.project.id, this.projectData)
        .then(() => {
          this.$router.push({ name: 'projects.show' });
        })
        .catch(err => {
          this.$log.error(err);
          this.feedback = false;
        })
      ;
    },
    checkCycle () {
      if (window.vg.isCyclic()) {
        this.alert('ATTENZIONE!', 'Il grafo è ciclico, assicurarsi della correttezza dei collegamenti.', 'Ok');
      } else {
        this.alert('OTTIMO!', 'Il grafo non presenta cicli.', 'Ok');
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    const id = to.params?.id;
    if (!id) {
      return next(from);
    }

    $api.fetchProjectViseaModule(id)
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.projectData = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>

<style lang="scss">
.mode-box {
  z-index: 1;
  box-shadow: 0px 0px 50px rgba($body-color,.35);
}

.appearance-order {
  width: 300px;
  border-left: 1px solid rgba($body-color, .075);
  // background: $white;

  .appearance-group {
    border: 1px solid lighten($body-color, 78%);
    background-color: lighten($body-color, 84%);
    border-radius: 5px;
    overflow: hidden;

    .group-header {
      background: lighten($body-color, 78%);
      color: $secondary;
    }

    &:last-child {
      margin-bottom: 0!important;
    }

    .remove-group {
      font-size: 12px;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.day {
      border-color: lighten($body-color, 76%);
      background-color: lighten($body-color, 82%);
    }

    &:hover {
      cursor: grab;
    }

    &.sortable-chosen {
      cursor: grabbing;
    }
  }
}

.polygon-info {
  width: 300px;
  border-right: 1px solid rgba($body-color, .075);

  .general-info {
    min-height: 0;
    flex-grow: 1;
  }

  .more-polygons {
    background: rgba($body-color, .075);
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-bottom: -1rem;
    min-height: 0;
    flex-shrink: 0;

    .btn {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .polygons-list {
      flex-wrap: wrap;
      max-height: 64px;
      overflow-y: scroll;
      margin-left: -.5rem;
      width: calc(100% + 1rem);
    }
  }
}
</style>

import alertService from '../services/alertService.js';

export default {
  data () {
    return {
      alertData: alertService.data,

      alertJustOpened: false,
      alertPromise: null,
      alertPromiseResolve: null,
    };
  },
  computed: {
    alertModalClosed () {
      return alertService.data.closed;
    },
  },
  methods: {
    alert (title, text, okLabel = 'Ok') {
      this.alertPromise = new Promise(resolve => {
        this.alertPromiseResolve = resolve;
        this.alertJustOpened = true;
        alertService.open(title, text, okLabel);
      });

      return this.alertPromise;
    },
    resetAlert () {
      alertService.close();
      this.alertPromise = null;
      this.alertPromiseResolve = null;
    },
  },
  watch: {
    alertModalClosed () {
      if (this.alertJustOpened) {
        this.alertJustOpened = false;

        return;
      }

      if (!this.alertPromiseResolve) {
        return;
      }

      this.alertPromiseResolve();
      this.resetAlert();
    },
  },
  mounted () {
    alertService.close();
  },
  destroyed () {
    alertService.close();
  },
};
